/**
 * @IndexDBAppState Component
 */
import { useEffect } from 'react';
import { useStateValue } from './pbxdomStore';
import { API } from '@pbxdomanalytics/app-api';

import { PBXDomStorage } from '@pbxdomanalytics/app-core';

const IndexDBAppState = (props) => {
    const { setLoading, pending } = props;

    const [state, dispatch] = useStateValue();

    const { log } = console;

    log('---state, dispatch----', state, dispatch);

    const { isAuth } = state;

    const loadInfo = () => {
        const { userID, token } = state;

        if (!isAuth) {
            setLoading(false);
            return;
        }

        if (!pending) {
            setLoading(true);
        }

        const promise1 = API.Account.GetUserPlans();
        const promise2 = PBXDomStorage.read(`globalAppState`, 'appState');
        const promise3 = API.Account.Info(userID, token);

        Promise.all([promise1, promise2, promise3])
            .then((values) => {
                const [plans, appState, userInfo] = values;

                let appStateValues = {};

                if (appState.validcache) {
                    appStateValues = appState.Data;
                }

                const currentPlan = 'full';

                const currentPlanInfo = plans.find(
                    (plan) => plan.name === currentPlan
                );

                dispatch([
                    {
                        type: 'setState',
                        payload: {
                            currentPlanInfo: currentPlanInfo || {},
                            ...appStateValues
                        }
                    },
                    {
                        type: 'setStateIndexDB',
                        payload: {
                            currentUserInfo: userInfo
                        }
                    }
                ]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadInfo();
    }, [isAuth]);

    return null;
};

export default IndexDBAppState;
