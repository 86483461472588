import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import * as Sentry from '@sentry/browser';

const COMMIT_HASH = '786c7fb-090220-180059';
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://88b4d9da9855438e80077be90e0d33a6@o64939.ingest.sentry.io/5255757'
    });
    Sentry.configureScope((scope) => {
        scope.setTag('commit', COMMIT_HASH);
    });
}
ReactDOM.render(
    <App />,

    document.getElementById('root')
);
