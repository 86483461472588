/**
 * @SendFeedback Component
 */
import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../helpers/pbxdomStore';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Loading, Field } from '@pbxdomanalytics/app-components';
import html2canvas from 'html2canvas';
import { uniqueId } from 'lodash';
import { addClass, removeClass } from '../../helpers/general';
import Draggable from 'react-draggable';
import './SendFeedback.scss';

const SendFeedback = () => {
    const [state, dispatch] = useStateValue();

    const [isOpen, setOpen] = useState(false);
    const [needToScreenShot, setNeedToScreenShot] = useState(false);
    const [overlayEditModeVisibility, setOverlayEditModeVisibility] =
        useState(false);

    useEffect(() => {
        if (isOpen !== state.isFeedbackMode) {
            handleModalStatus(state.isFeedbackMode);

            if (state.isFeedbackMode) {
                setNeedToScreenShot(true);
            }
        }
    }, [state.isFeedbackMode]);

    useEffect(() => {}, []);

    const toggle = () => {
        handleModalStatus(!isOpen, true);
        if (isOpen) {
            resetEditingMode();
            resetFeedbackForm();
        }
    };

    const apply = () => {};

    const handleModalStatus = (status, update = false) => {
        if (update) {
            dispatch({
                type: 'setState',
                payload: {
                    isFeedbackMode: status
                }
            });
        }

        setOpen(status);

        setOverlayEditModeVisibility(status);
    };

    const initialValues = {
        feedbackScreenshot: 1,
        feedbackMessage: ''
    };

    const [values, setValues] = useState(initialValues);
    /**
     * handle Change Form fields
     * @param name
     * @param value
     */
    const handleChangeForm = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const editScreenshot = () => {
        setOpen(false);
        addClass(document.body, 'edit-screenshot-mode');
    };

    const editDoneScreenshot = () => {
        reqScreenshot(() => {
            setOpen(true);
            removeClass(document.body, 'edit-screenshot-mode');
        });
    };

    const [screenshotPending, setScreenshotPending] = useState(false);

    const [screenshotSrc, setScreenshotSrc] = useState('');

    /**
     * TODO: html canvas has problem with some SVGs like Font Awesome
     * TODO: html canvas has problem with sticky header
     */
    const takeScreenshot = (callback) => {
        const container = document.getElementById('root');

        html2canvas(container, {
            // width: 1920,
            height: window.innerHeight,
            ignoreElements: () => {
                //console.log("----element----", element.querySelector('.dashboard-actions-toggle'));
                //return !!element.querySelector('.dashboard-actions-toggle')
            }
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setScreenshotSrc(imgData);
                // const link = document.createElement('a');
                // link.download = 'dashboard.png';
                // link.href = imgData;
                // link.click();
                if (callback) {
                    callback();
                }
            })
            .finally(() => {
                setScreenshotPending(false);
            });
    };

    const reqScreenshot = (callback) => {
        setScreenshotPending(true);

        //A good way to optimize performance
        setTimeout(() => {
            takeScreenshot(callback);
        }, 300);
    };

    const { formatMessage } = useIntl();

    const [addedItems, setAddedItems] = useState([]);

    const [addingItem, setAddingItem] = useState(false);

    const [highlightType, setHighlightType] = useState('issue');

    const dragDefault = (ev) => {
        ev.preventDefault();
        return false;
    };

    const resetEditingMode = () => {
        setAddedItems([]);
        setAddingItem(false);
        setHighlightType('issue');
    };

    const resetFeedbackForm = () => {
        setScreenshotSrc('');
        setValues(initialValues);
    };

    const dragStart = (ev) => {
        if (!ev.target.closest('.box-highlight-bar')) {
            setAddingItem({
                id: uniqueId('highlight_box_'),
                x0: ev.clientX,
                y0: ev.clientY,
                width: 0,
                height: 0,
                top: ev.clientX,
                left: ev.clientY,
                type: highlightType
            });
        }

        return false;
    };

    const drawingHighlightBox = (ev) => {
        if (!ev.target.closest('.box-highlight-bar') && addingItem) {
            const { x0, y0, id, type } = addingItem;

            const newX = ev.clientX;

            const newY = ev.clientY;

            const minX = Math.min(x0, newX);

            const minY = Math.min(y0, newY);

            setAddingItem({
                id,
                x0,
                y0,
                width: Math.abs(newX - x0),
                height: Math.abs(newY - y0),
                top: minY,
                left: minX,
                type
            });
        }

        return false;
    };

    const endDrawHighlightBox = (ev) => {
        if (!ev.target.closest('.box-highlight-bar')) {
            if (addingItem && addingItem.height > 0 && addingItem.width > 0) {
                setAddedItems([...addedItems, addingItem]);
            }

            setAddingItem(false);
        }

        return false;
    };

    const removeHighlight = (id) => {
        setAddedItems(addedItems.filter((item) => item.id !== id));
    };

    const { feedbackScreenshot, feedbackMessage } = values;

    return (
        <section className={`send-feedback-container`}>
            {overlayEditModeVisibility && (
                <div
                    className="overlay-screenshot-mode"
                    onMouseDown={dragStart}
                    onMouseMove={drawingHighlightBox}
                    onMouseUp={endDrawHighlightBox}
                    onDragStart={dragDefault}
                    onDrag={dragDefault}
                    onDragEnd={dragDefault}
                >
                    <Draggable
                        handle=".handle"
                        defaultPosition={{
                            x: window.innerWidth / 2 - 116,
                            y: window.innerHeight / 2 - 28
                        }}
                        bounds="parent"
                        scale={1}
                    >
                        <div
                            className="box-highlight-bar d-flex"
                            data-html2canvas-ignore
                        >
                            <div className="handle">
                                <i className="lnr-move" />
                            </div>
                            <div
                                className={`highlight-issue ${
                                    highlightType === 'issue' ? 'active' : ''
                                }`}
                                onClick={setHighlightType.bind(null, 'issue')}
                            >
                                <span className="color" />
                            </div>
                            <div
                                className={`hide-sensitive-info ${
                                    highlightType === 'sensitiveInfo'
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={setHighlightType.bind(
                                    null,
                                    'sensitiveInfo'
                                )}
                            >
                                <span className="color" />
                            </div>
                            <div
                                className="highlight-done"
                                onClick={editDoneScreenshot}
                            >
                                <Loading show={screenshotPending}>
                                    {' '}
                                    Done{' '}
                                </Loading>
                            </div>
                        </div>
                    </Draggable>

                    {addingItem &&
                        addingItem.height > 0 &&
                        addingItem.width > 0 && (
                            <div
                                key={addingItem.id}
                                className={`highlighted-item ${addingItem.type}`}
                                style={{
                                    width: addingItem.width,
                                    height: addingItem.height,
                                    top: addingItem.top,
                                    left: addingItem.left
                                }}
                            />
                        )}

                    {addedItems.map(
                        ({ width, height, top, left, id, type }) => (
                            <div
                                key={id}
                                className={`highlighted-item ${type}`}
                                style={{
                                    width,
                                    height,
                                    top,
                                    left
                                }}
                            >
                                <span
                                    className="remove"
                                    onClick={removeHighlight.bind(null, id)}
                                >
                                    <i className="lnr-cross-circle" />
                                </span>
                            </div>
                        )
                    )}
                </div>
            )}
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                className="send-feedback-modal"
                onOpened={() => {
                    if (needToScreenShot) {
                        reqScreenshot();
                        setNeedToScreenShot();
                    }
                }}
                centered
            >
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="views.sendFeedback.dialogTitle" />
                </ModalHeader>
                <ModalBody>
                    <section className="">
                        <div className="mt-2 mb-2 send-feedback-icon text-center">
                            <i className="lnr-bug icon-gradient bg-sunny-morning" />
                        </div>

                        <h5 className="pl-4 pr-4">
                            <FormattedMessage id="views.sendFeedback.title" />
                        </h5>

                        <div className="mt-1 mb-5 text-mute pl-4 pr-4">
                            <FormattedMessage id="views.sendFeedback.subtitle" />
                        </div>

                        <div className="mt-4 mb-5 pl-4 pr-4">
                            <Field
                                fieldType="checkbox"
                                className="mr-1"
                                name="feedbackScreenshot"
                                label={formatMessage({
                                    id: 'views.sendFeedback.includeScreenshot'
                                })}
                                onChange={handleChangeForm}
                                defaultValue={feedbackScreenshot}
                            />

                            {!!feedbackScreenshot && (
                                <Loading show={screenshotPending}>
                                    <div
                                        className="send-feedback-screenshot mb-3"
                                        onClick={editScreenshot}
                                    >
                                        <img
                                            className="img-responsive"
                                            src={screenshotSrc}
                                            alt=""
                                        />
                                        {!screenshotPending && (
                                            <div className="select-edit-mode">
                                                <div>
                                                    <i className="lnr-select text-primary" />
                                                </div>
                                                <div className="text-primary font-italic">
                                                    Click to highlight or hide
                                                    info
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Loading>
                            )}

                            <Field
                                fieldType="textarea"
                                className="mb-4"
                                name="feedbackMessage"
                                label={formatMessage({
                                    id: 'views.sendFeedback.message'
                                })}
                                onChange={handleChangeForm}
                                defaultValue={feedbackMessage}
                                /*error={errors['message']}*/
                                rows={6}
                            />
                        </div>

                        <div className="text-right pr-4">
                            <Button
                                className="customize-button mb-4 pl-4 pr-4 btn-pill text-uppercase"
                                color="warning"
                                onClick={apply}
                                size="lg"
                                disabled={screenshotPending}
                            >
                                <b>
                                    <FormattedMessage id="views.sendFeedback.sendBtn" />
                                </b>
                            </Button>
                        </div>
                    </section>
                </ModalBody>
            </Modal>
        </section>
    );
};

export default SendFeedback;
