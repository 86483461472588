import React, { useState } from 'react';
import '../assets/App.scss';
import '@pbxdomanalytics/app-core/dist/assets/App.scss';
import { useCookies } from 'react-cookie';
import ToastContainer from '../components/toastify/ToastContainer';
import {
    StateProvider,
    getInitialCookies,
    getInitialGlobalStates
} from '../helpers/pbxdomStore';
import Routes from '../routing/Routes';
import LanguageProvider from '../languageProvider/LanguageProvider';
import { Spinner } from 'reactstrap';
import { PBXDomStorage } from '@pbxdomanalytics/app-core';
import IndexDBAppState from '../helpers/IndexDBAppState.jsx';
import SendFeedback from './General/SendFeedback';
import {
    GsIdentifyUser,
    CustomerFavicon
} from '@pbxdomanalytics/app-components';

const App = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['pbxdomv3']);

    let initialStatePBXDom = getInitialGlobalStates();

    // console.log("----cookies---", cookies);

    if (cookies.pbxdomv3 !== undefined) {
        initialStatePBXDom = {
            ...initialStatePBXDom,
            ...cookies.pbxdomv3
        };
    }

    if (cookies.dashboard_test_ids !== undefined) {
        initialStatePBXDom = {
            ...initialStatePBXDom,
            dashboard_test_ids: cookies.dashboard_test_ids
        };
    }

    // console.log("-------initialStatePBXDom-------", initialStatePBXDom);

    const setMainState = (state, type, payload) => {
        switch (type) {
            //********************************************************************* */
            case 'setState':
                return { ...state, ...payload };

            case 'removeCookie':
                removeCookie('pbxdomv3');
                return { ...state, ...payload };

            case 'setStateCookie':
                const initial =
                    cookies.pbxdomv3 !== undefined
                        ? cookies.pbxdomv3
                        : getInitialCookies();

                setCookie(
                    'pbxdomv3',
                    { ...initial, ...payload },
                    {
                        path: '/',
                        maxAge: 86400 * 30 * 12, //Todo => should link with keep me login on Login page
                        sameSite: true
                    }
                );

                return { ...state, ...payload };

            case 'setStateIndexDB':
                PBXDomStorage.read(`globalAppState`, 'appState').then(
                    (LData) => {
                        const indexDBAppState = LData.validcache
                            ? LData.Data
                            : {};

                        PBXDomStorage.write(
                            'globalAppState',
                            {
                                ...indexDBAppState,
                                ...payload
                            },
                            1440,
                            'appState'
                        );
                    }
                );

                return { ...state, ...payload };
            default:
                return state;
        }
    };

    const MainReducer = (state, action) => {
        const actions = Array.isArray(action) ? action : [action];

        let currentState = state;

        actions.forEach((currentAction) => {
            const { type, payload = {} } = currentAction;

            currentState = setMainState(currentState, type, payload);
        });

        return currentState;
    };

    const [pending, setLoading] = useState(true);

    return (
        <StateProvider reducer={MainReducer} initialState={initialStatePBXDom}>
            <LanguageProvider>
                {pending ? (
                    <div className="flex-center h-100">
                        <Spinner type="grow" color="primary" />
                    </div>
                ) : (
                    <>
                        <ToastContainer />
                        <Routes />
                        <SendFeedback />
                        <GsIdentifyUser />
                        <CustomerFavicon />
                    </>
                )}

                <IndexDBAppState pending={pending} setLoading={setLoading} />
            </LanguageProvider>
        </StateProvider>
    );
};

export default App;
