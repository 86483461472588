/**
 * @Routes component
 * Defines global routes.
 * [lazy](https://reactjs.org/docs/code-splitting.html) uses for code-splitting.
 * All views dependencies must be passed as props in this file.
 */
import React, { Suspense, lazy } from 'react';
import { Router, Redirect, Switch } from 'react-router-dom';
import {
    history,
    PublicRoute,
    RestrictedRoute
} from '@pbxdomanalytics/app-core';
import { useStateValue } from '../helpers/pbxdomStore';
import { Spinner } from 'reactstrap';
import { UpgradePlan } from '@pbxdomanalytics/app-components';

const Home = lazy(() => import('../views/home/Home'));
const PBXDomDashboardContainer = lazy(() =>
    import('../views/Dashboard/Dashboard')
);
const Login = lazy(() => import('../views/Auth/login/Login'));
const Mobile = lazy(() => import('../views/mobile/Mobile'));
const RecoverPassword = lazy(() => import('../views/Auth/RecoverPassword'));
const ResetPassword = lazy(() =>
    import('../views/Auth/RecoverPassword/ResetPassword')
);
const SignUp = lazy(() => import('../views/Auth/Signup/SignUp'));
const SignUpCompleted = lazy(() =>
    import('../views/Auth/Signup/signupCompleted')
);

const EmailVerification = lazy(() =>
    import('../views/Auth/emailVerification/EmailVerification.js')
);

const TokenLogin = lazy(() => import('../views/Auth/TokenLogin/TokenLogin'));
const ShareLogin = lazy(() => import('../views/Auth/ShareLogin'));
const Report = lazy(() => import('../views/Report/ReportMain/ReportMain'));
const ReportContainer = lazy(() =>
    import('../views/Report/ReportContainer/ReportContainer')
);

const Settings = lazy(() => import('../views/settings/SettingsManagement'));
const Subscription = lazy(() =>
    import('../views/settings/subscription/Subscription')
);

const Payment = lazy(() => import('../views/settings/payment/Payment'));

const Invoice = lazy(() => import('../views/settings/invoice/Invoice'));

const APIKey = lazy(() => import('../views/settings/apiKey/APIKey'));
const Recipients = lazy(() =>
    import('../views/settings/recipients/Recipients')
);

const SharedDashboards = lazy(() =>
    import('../views/settings/sharedDashboards/SharedDashboards')
);

const ChangePassword = lazy(() =>
    import('../views/settings/changePassword/ChangePassword')
);

const DepartmentGroups = lazy(() =>
    import('../views/settings/departmentGroups/DepartmentGroups')
);

const ExtensionsTrunk = lazy(() =>
    import('../views/settings/extensionsTrunk/ExtensionsTrunk')
);

const Alert = lazy(() => import('../views/settings/alert/Alert'));

const Cost = lazy(() => import('../views/settings/cost/Cost'));
const Support = lazy(() => import('../views/Support'));

const Collectors = lazy(() =>
    import('../views/settings/collectors/Collectors')
);

const BillingSettings = lazy(() =>
    import('../views/settings/billing-settings/BillingSettings')
);

const PhoneSystem = lazy(() => import('../views/phoneSystem/PhoneSystem'));

const Routes = () => {
    const [state] = useStateValue();
    const { dashboardTheme = 'light' } = state;
    const [currentTheme, setCurrentTheme] = React.useState(dashboardTheme);
    const setTheme = () => {
        if (window.location.href.indexOf('dashboard') >= 0) {
            document.body.classList.remove(currentTheme);
            document.body.classList.add(dashboardTheme);
            setCurrentTheme(dashboardTheme);
        } else {
            document.body.className = '';
        }
    };

    React.useEffect(() => {
        setTheme();
    }, [dashboardTheme]);
    React.useEffect(() => {
        const unlisten = history.listen(() => {
            setTheme();
        });
        return () => {
            unlisten();
        };
    }, [1]);
    return (
        <div className={`app-wrapper ${dashboardTheme}`}>
            <Router history={history}>
                <Suspense
                    fallback={
                        <div className="flex-center h-100">
                            <Spinner type="grow" color="primary" />
                        </div>
                    }
                >
                    <Switch>
                        <RestrictedRoute
                            exact
                            path="/"
                            component={Home}
                            componentProps={{ Data: state }}
                        />

                        <PublicRoute
                            exact
                            path="/report-public"
                            component={Report}
                            componentProps={{
                                Data: state,
                                mode: { view: 'v', type: 'report' }
                            }}
                        />

                        <PublicRoute
                            exact
                            path="/report-public/:repid/:cid"
                            component={ReportContainer}
                            componentProps={{
                                Data: state,
                                mode: { view: 'v', type: 'report' }
                            }}
                        />

                        <RestrictedRoute
                            exact
                            path="/report"
                            component={Report}
                            componentProps={{
                                Data: state,
                                mode: { view: 'n' }
                            }}
                        />
                        <PublicRoute
                            exact
                            path="/support"
                            component={Support}
                            componentProps={{
                                Data: state,
                                mode: { view: 'n' }
                            }}
                        />

                        <RestrictedRoute
                            exact
                            path="/report/:repid/:cid" ///:name
                            component={ReportContainer}
                            componentProps={{
                                Data: state,
                                mode: { view: 'n' }
                            }}
                        />

                        <RestrictedRoute
                            exact
                            path="/dashboard/:dashid"
                            component={PBXDomDashboardContainer}
                            componentProps={{
                                Data: state,
                                mode: { view: 'n' }
                            }}
                        />

                        <PublicRoute
                            exact
                            path="/shareddashboard/:dashid/:token/:userid"
                            component={ShareLogin}
                            componentProps={{
                                Data: state,
                                mode: { view: 'v' }
                            }}
                        />

                        <PublicRoute
                            exact
                            path="/export/:path/:token/:userId"
                            component={TokenLogin}
                            componentProps={{
                                Data: state,
                                mode: { view: 'v' }
                            }}
                        />

                        <PublicRoute
                            exact
                            path="/dashboardpresent/:dashid"
                            component={PBXDomDashboardContainer}
                            componentProps={{
                                Data: state,
                                mode: { view: 'v', type: 'share' }
                            }}
                        />

                        <PublicRoute
                            exact
                            path="/dashboard-view/:dashid"
                            component={PBXDomDashboardContainer}
                            componentProps={{
                                Data: state,
                                mode: { view: 'v', type: 'export' }
                            }}
                        />

                        <PublicRoute exact path="/mobile" component={Mobile} />

                        <PublicRoute path="/login" component={Login} />

                        <PublicRoute
                            path="/page-login/:login/:redirect"
                            component={Login}
                        />

                        <PublicRoute
                            path="/direct-login/:login/:username/:password/:redirect"
                            component={Login}
                        />

                        <PublicRoute
                            path="/forgotpassword"
                            component={RecoverPassword}
                        />

                        <PublicRoute
                            path="/reset-password/:userId/:token"
                            component={ResetPassword}
                        />

                        <PublicRoute path="/signup" component={SignUp} />

                        <PublicRoute
                            path="/signupcompleted"
                            component={SignUpCompleted}
                        />

                        <PublicRoute
                            path="/verify"
                            component={EmailVerification}
                        />

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings"
                                    component={Settings}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/subscription"
                                    component={Subscription}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/payment"
                                    component={Payment}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/invoice"
                                    component={Invoice}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/billing-settings"
                                    component={BillingSettings}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/api-key"
                                    component={APIKey}
                                    componentProps={{ Data: state }}
                                />
                            )}
                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/recipients"
                                    component={Recipients}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/shared-dashboards"
                                    component={SharedDashboards}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        <RestrictedRoute
                            exact
                            path="/settings/change-password"
                            component={ChangePassword}
                            componentProps={{ Data: state }}
                        />

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/department-groups"
                                    component={DepartmentGroups}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/ext-trunk"
                                    component={ExtensionsTrunk}
                                    componentProps={{ Data: state }}
                                />
                            )}
                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/alert"
                                    component={Alert}
                                    componentProps={{ Data: state }}
                                />
                            )}
                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/cost"
                                    component={Cost}
                                    componentProps={{ Data: state }}
                                />
                            )}
                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/settings/collectors"
                                    component={Collectors}
                                    componentProps={{ Data: state }}
                                />
                            )}
                        {state.currentUserInfo.userAccessLevel !== 3 &&
                            !state.currentUserInfo.isSubAccount && (
                                <RestrictedRoute
                                    exact
                                    path="/phone-system"
                                    component={PhoneSystem}
                                    componentProps={{ Data: state }}
                                />
                            )}

                        <Redirect to="/" />
                    </Switch>
                </Suspense>
                <UpgradePlan />
            </Router>
        </div>
    );
};

export default Routes;
