/**
 * Language provider based intl
 */
import { createIntl, createIntlCache } from 'react-intl';
import enConfig from './configs/en-US';

/**
 * List of languages configs
 * @type {{en: object}} an object of intlConfig
 */
export const appLocale = {
    en: () => import('./configs/en-US.js'),
    es: () => import('./configs/es-ES.js')
};

/**
 * This is optional but highly recommended since it prevents memory leak
 * @type {IntlCache}
 */
const cache = createIntlCache();

export const getDefaultIntl = () => {
    return createIntl(
        {
            ...enConfig
        },
        cache
    );
};

export const getCurrentIntl = (currentLocal) => {
    const configFile = appLocale[currentLocal];

    return new Promise((resolve) => {
        return configFile().then((module) => {
            resolve(
                createIntl(
                    {
                        ...module.default,
                        messages: {
                            ...enConfig.messages,
                            ...module.default.messages
                        }
                    },
                    cache
                )
            );
        });
    });
};
