/**
 * English language config
 */
import enMessages from '../locales/en_US.json';

const enLangConfig = {
    messages: {
        ...enMessages
    },
    locale: 'en-US',
    onError: (error) => {
        console.error(error);
    }
};

export default enLangConfig;
