import { GeneralHelper } from '@pbxdomanalytics/app-core';

export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const {
    addClass,
    addTimes,
    addToRecentlyList,
    closestElement,
    convertImgToBase64,
    getAlertConditions,
    getAlertTypes,
    getAlertVariableSamples,
    getAlertVariables,
    getAlertVariablesTypes,
    getCallOrderByOptions,
    getConnectionType,
    getDateFromPredefined,
    getDateRanges,
    getDateRangesKeys,
    getDynamicIntlName,
    getListFromIds,
    getTime,
    getUrlQueryParameter,
    hasClass,
    imageExists,
    isElementInView,
    isFuncExist,
    isToday,
    isValidJSONString,
    minusTimes,
    removeClass,
    setAdvancedParams
} = GeneralHelper;
