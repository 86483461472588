import { pbxdomStore } from '@pbxdomanalytics/app-core';

export const {
    useStateValue,
    PBXDomCookies,
    StateProvider,
    CreateContainerComponent,
    withContainer,
    getInitialCookies,
    getInitialGlobalStates
} = pbxdomStore;
