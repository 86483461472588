// @flow
/**
 * @LanguageProvider component
 * A wrapper for [RawIntlProvider] to load current language of app
 */
import * as React from 'react';
import { RawIntlProvider } from 'react-intl';
import { useStateValue } from '../helpers/pbxdomStore';
import { getCurrentIntl, getDefaultIntl } from './index';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const { useEffect, useState } = React;

const LanguageProvider = (props) => {
    const [state] = useStateValue();

    const { children } = props;

    const { lang } = state;

    const [intl, setIntl] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (lang !== 'en') {
            const currentIntl = getCurrentIntl(lang);

            currentIntl
                .then((result) => {
                    setIntl(result);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setIntl(getDefaultIntl());
        }
    }, [lang]);

    if (loading) {
        return (
            <div className="flex-center h-100">
                <Spinner type="grow" color="primary" />
            </div>
        );
    }

    if (!intl) {
        return null;
    }

    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

LanguageProvider.propTypes = {
    children: PropTypes.node
};

export default LanguageProvider;
